import { colors, Flex, FlexProps } from "@biblioteksentralen/react";
import {
  digitalLibraryServiceCategories,
  digitalLibraryServiceCategoryIcons,
  ResolvedPaletteColor,
} from "@libry-content/common";
import { DigitalLibraryServiceCategory } from "@libry-content/types";
import { getPath } from "../../../../utils/getPath";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { ResolvedDigitalLibraryServiceSummary } from "@libry-content/common";
import { ResolvedServiceSummary } from "@libry-content/common/src/services/groq/resolveService";
import SingleItemBanner from "../SingleItemBanner";
import { getContainerQuery } from "./utils";

const isService = (item: unknown): item is ResolvedServiceSummary =>
  typeof item === "object" && (item as any)?.["_type"] === "service";

type Service = ResolvedServiceSummary | ResolvedDigitalLibraryServiceSummary;

export function FeaturedServiceBanner(props: {
  service: Service;
  bannerColor: ResolvedPaletteColor;
  tagline?: string;
}) {
  const { bannerColor, service } = props;
  const { t, ts } = useTranslation();
  const title = ts<string>(isService(service) ? service.title : service.name);

  const tagline =
    props.tagline ??
    (isService(service)
      ? ts(service.serviceType?.label)
      : ts(digitalLibraryServiceCategories[service.category ?? "other-digital-resources"]));

  const fallbackImage =
    !isService(service) && !service.image?.asset ? (
      <Flex
        height="100%"
        background={`linear-gradient(45deg, ${colors.grey15}, ${colors.grey10})`}
        color={bannerColor.css.background}
      >
        <DigitalLibraryServiceIcon
          margin="auto"
          sx={getContainerQuery({
            base: { height: "8rem", width: "8rem" },
            sm: { height: "12rem", width: "12rem" },
            md: { height: "14rem", width: "14rem" },
          })}
          category={service.category}
          background="none"
        />
      </Flex>
    ) : undefined;

  return (
    <SingleItemBanner
      aria-description={t("tjeneste")}
      as="aside"
      bannerColor={bannerColor}
      sanityImageSrc={service.image}
      seeMore={{ href: getPath(service), label: t("Se mer") }}
      editDocumentProps={service}
      customImageComponent={fallbackImage}
      title={title}
      tagline={tagline}
      teaser={ts(service.teaser)}
      promotion={service.promotion}
    />
  );
}

const DigitalLibraryServiceIcon = ({
  category,
  ...flexProps
}: FlexProps & { category: DigitalLibraryServiceCategory | undefined }) => {
  const Icon = digitalLibraryServiceCategoryIcons[category ?? "other-digital-resources"];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100%"
      position="relative"
      width={{ base: "8rem", sm: "10rem", md: "12rem" }}
      margin="auto"
      padding={{ base: "2rem 0 1rem", md: "0" }}
      {...flexProps}
    >
      <Icon color="currentColor" width="100%" height="100%" />
    </Flex>
  );
};
