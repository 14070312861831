import { Grid, GridProps } from "@biblioteksentralen/react";
import { seededRandomFromArray } from "@biblioteksentralen/utils";
import { eventIsCancelled, isDigitalLibraryCustomService, isDigitalLibraryTemplateCode } from "@libry-content/common";
import { Documents } from "@libry-content/types";
import { sort } from "radash";
import { FrontPageData } from "../../../../app/[locale]/sites/[siteDomain]/page";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../../utils/useSitePalette";
import { DefaultContainer } from "../../../ContentContainer";
import { FrontpageAdventCalendarBanner } from "../../../adventCalendar/FrontpageAdventCalendarBanner";
import { BibliobattlesBanner } from "../../../campaigns/bibliobattles/BibliobattlesBanner";
import { CustomCampaignBanner } from "../../../campaigns/customCampaign/CustomCampaignBanner";
import { ResolvedDigitalLibraryServiceSummary } from "@libry-content/common";
import { SommerlesBanner } from "../../../sommerles/SommerlesBanner";
import { FeaturedEventBanner } from "../events/FeaturedEventBanner";
import { FeaturedListBanner } from "./FeaturedListBanner";
import { FeaturedRecommendationBanner } from "./FeaturedRecommendationBanner";
import { FeaturedServiceBanner } from "./FeaturedServiceBanner";
import { FeaturedStaticPageBanner } from "./FeaturedStaticPageBanner";
import { FeatureableItem } from "./sanityQuery";

const hasCode = (item: unknown): item is { code: string } =>
  typeof item === "object" && typeof (item as any)?.["code"] === "string";

const isFeaturedDigitalLibraryService = (item: FeatureableItem): item is ResolvedDigitalLibraryServiceSummary =>
  isDigitalLibraryCustomService(item) || (hasCode(item) && isDigitalLibraryTemplateCode(item.code));

const useFeatureBannerColors = () => {
  const palette = useSitePalette();

  return [
    palette.colors.lightaccent3,
    palette.colors.lightaccent2,
    palette.colors.lightaccent4,
    palette.colors.darkaccent1,
    palette.colors.darkaccent2,
    palette.colors.darkaccent4,
  ];
};

const FeaturedItem = ({ featuredItem }: { featuredItem: FeatureableItem }) => {
  const { t } = useTranslation();
  const featureBannerColors = useFeatureBannerColors();
  const color = seededRandomFromArray(featureBannerColors, featuredItem._id);

  if (isFeaturedDigitalLibraryService(featuredItem)) {
    return <FeaturedServiceBanner bannerColor={color} service={featuredItem} />;
  }

  switch (featuredItem._type) {
    case "sommerles":
      return <SommerlesBanner sommerles={featuredItem} onLandingPage />;
    case "adventCalendar":
      return <FrontpageAdventCalendarBanner adventCalendar={featuredItem} />;
    case "bibliobattles":
      return <BibliobattlesBanner bibliobattles={featuredItem} />;
    case "customCampaign":
      return <CustomCampaignBanner bannerColor={color} customCampaign={featuredItem} />;
    case "event":
    case "resolvedRepeatedEventOccurence":
      return <FeaturedEventBanner bannerColor={color} event={featuredItem} />;
    case "service":
      return <FeaturedServiceBanner bannerColor={color} service={featuredItem} />;
    case "list":
      return <FeaturedListBanner list={featuredItem} tagline={t("Nye anbefalinger")} />;
    case "recommendation":
      return <FeaturedRecommendationBanner bannerColor={color} recommendation={featuredItem} />;
    case "staticPage":
      return <FeaturedStaticPageBanner bannerColor={color} staticPage={featuredItem} />;
    default:
      // @ts-ignore
      console.error(`Could not show banner for featuredItem:`, { type: featuredItem?._type, id: featuredItem._id });
      return null;
  }
};

const FeaturedBanner = ({ featuredItems }: { featuredItems?: FeatureableItem[] }) => {
  if (!featuredItems || featuredItems.length === 0) return null;

  if (featuredItems.length === 1) {
    return <FeaturedItemsContainer featuredItems={featuredItems} />;
  }

  if (featuredItems.length === 2) {
    return (
      <FeaturedItemsContainer
        featuredItems={featuredItems}
        gridTemplateColumns={{
          base: "1fr",
          md: "1fr 1fr",
        }}
      />
    );
  }

  return (
    <FeaturedItemsContainer
      featuredItems={featuredItems}
      gridTemplateColumns={{
        base: "1fr",
        md: "1fr 1fr",
        lg: "repeat(3, 1fr)",
      }}
    />
  );
};

type FeaturedItemsContainerProps = {
  featuredItems: FeatureableItem[];
} & GridProps;

const FeaturedItemsContainer = ({ featuredItems, ...gridProps }: FeaturedItemsContainerProps) => {
  return (
    <DefaultContainer withoutPaddingXBellow="md">
      <Grid gap=".75rem" {...gridProps}>
        {featuredItems.map((item, index) => (
          <Grid
            key={item._id}
            gridColumn={
              featuredItems.length === 3
                ? {
                    base: "span 1", // For small screens, each box spans 1 column
                    md: index === 0 ? "span 2" : "span 1", // For medium screens, first box spans 2 columns, others span 1
                    lg: "span 1", // For large screens, each box spans 1 column
                  }
                : undefined
            }
          >
            <FeaturedItem key={item._id} featuredItem={item} />
          </Grid>
        ))}
      </Grid>
    </DefaultContainer>
  );
};

/**
 * NOTE: Update docs when making changes: https://bs-redia.atlassian.net/l/cp/e54CX5CS
 */
export const useFeaturedBanners = (props: FrontPageData) => {
  const itemsFirstBanner = props.promotedItems[0]
    ? props.promotedItems.slice(0, 1)
    : props.listsAndRecommendations.slice(0, 1); // Dersom det ikke finnes noe featuret item, fallbacker vi til siste liste eller anbefaling

  const otherEvents = sort(
    props.events.filter((event) => !eventIsCancelled(event.eventStatus)),
    (event) => (event.promotion ? -1 : 1) // Prioriterer promoterte arrangementer
  );

  const remainingFeaturedItems = removeItems(props.promotedItems, itemsFirstBanner);
  const itemsSecondBanner = remainingFeaturedItems.length
    ? remainingFeaturedItems.slice(0, 3)
    : // Dersom det ikke finnes noe featured-item fallbacker vi til å vise førstkommende arrangement
      removeItems(otherEvents, itemsFirstBanner).slice(0, 1);

  return {
    firstFeaturedBanner: <FeaturedBanner featuredItems={itemsFirstBanner} />,
    secondFeaturedBanner: <FeaturedBanner featuredItems={itemsSecondBanner} />,
    removeFeaturedItems: <T extends Pick<Documents, "_id">>(items: T[]) =>
      removeItems(items, [...itemsFirstBanner, ...itemsSecondBanner]),
  };
};

// Sørger for at det samme dokumentet ikke blir "featured" flere ganger
const removeItems = <T extends Pick<Documents, "_id">>(
  items: T[],
  itemsToRemove: (Pick<Documents, "_id"> | null | undefined)[]
) => items?.filter((item) => !itemsToRemove?.some((itemToRemovoe) => itemToRemovoe?._id === item?._id)) as T[];
